<template>
    <div class="terms-of-service">
        <md-card>
            <md-card-header>
                <h1 class="md-title">Terms of Service</h1>
            </md-card-header>
            <md-card-content v-html="content">
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            content: ""
        };
    },
    methods: {
        async get() {
            var result = await this.$store.dispatch("crud/get", {
                api: "settings/terms-of-service"
            });
            if (result) {
                this.content = result.value;
            }
        }
    },
    mounted() {
        this.get();
    }
};
</script>
<style lang="less">
.terms-of-service {
    .md-card {
        max-width: 1000px;
        margin: 10px auto;
    }
}
</style>
